import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PostList from '../components/postList'
import Pagination from '../components/pagination'

const Blog = props => {
  const { data, pageContext, location } = props
  const { edges: posts } = data.allWordpressPost

  return (
    <>
      <Hero className="hero" />
      <PostList posts={posts} title="Latest posts" />
      <Pagination pageContext={pageContext} pathPrefix="/" />
    </>
  )
}

export default Blog

Blog.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`

const Hero = styled.div`
  content: '';
  display: block;
  width: 100%;
  padding-top: 56.25%;
  background: red;
  @media (min-width: 1025px) {
    padding-top: 600px;
  }
`
